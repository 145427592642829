<template>
	<div style="background: #fff;padding: 15px;" class="sethadle">
		<!-- tab切换 -->
		<el-tabs v-model="listState" @tab-click="filterFun">
			<el-tab-pane v-for="item in listStateList" :key="item.id" :label="item.value" :name="item.id+''"></el-tab-pane>
		</el-tabs>
		<div v-if="currentTab==0" class="table-container" v-loading='tabLoading'>
			<p style="color:red;font-size: 14px;">建议上传宽750px，高1040px，图片大小不超过2M</p>
			
			<!-- 拖拽层 -->
			<div class="button-list">
				<el-button @click="openDrag" v-if="!drag_open" :disabled="imageList1.length <= 1" type="text" size="small" class="operation-success">点击拖拽</el-button>
				<el-button @click="saveDrag(1)" v-if="drag_open" type="text" size="small" class="operation-success">保存</el-button>
				<el-button @click="cancleDrag" v-if="drag_open" type="text" size="small" class="operation-error">取消</el-button>
			</div>
			<div class="list-wrap" style="width:100%;margin-bottom: 40px;" v-show="drag_open">
				<draggable 
					style='width:100%;' 
					v-model="imageList1" 
					:options="{
						animation: 150,
						ghostClass: 'sortable-ghost',
						chosenClass: 'chosenClass',
						scroll: true,
						scrollSensitivity: 200
					}">
					<div style="margin-bottom: 50px;display:inline-block;width:25%;" v-for="(item, index) in imageList1" :key="index">
						<img :src="imgUrl+item.PicUrl" alt="" style="width: 188px;height: 266px;">
					</div>

				</draggable>
			</div>
			<div v-show="!drag_open" style="display: flex;flex-wrap: wrap;margin-bottom: 40px;" >
				<div style="width: 25%;margin-bottom: 50px;" v-for="(item,index) in imageList1" :key="index">
					<div style="display: flex;flex-direction:column;align-items: center;width: 188px;height: 300px;">
						<img :src="imgUrl+item.PicUrl" alt="" style="width: 188px;height: 266px;">
						<div style="text-align: center;font-size: 14px;color: #F56C6C;padding: 10px 30px;cursor:pointer;" @click="deleteImg1(index)">删除</div>
					</div>
				</div>
				<el-upload style="width: 25%;display:inline-block;" :action="imgApi" list-type="picture-card" :on-success="handleAvatarSuccessfu" :file-list="fileList1" accept="image/png,image/jpeg,image/jpg,image/gif" :on-remove="handleRemovefu" :show-file-list="false">
					<div>
						<i></i>
						<div style="color: #409EFF;font-size: 12px;width:188px;margin-top: 70px;">+添加图片</div>
					</div>
				</el-upload>
			</div>
			
		</div>
		
		<div v-else class="table-container"  v-loading='tabLoading'>
			<p style="color:red;font-size: 14px;">建议上传宽750px，高1040px，图片大小不超过2M</p>

			<!-- 拖拽层 -->
			<div class="button-list">
				<el-button @click="openDrag" v-if="!drag_open2" :disabled="imageList2.length <= 1" type="text" size="small" class="operation-success">点击拖拽</el-button>
				<el-button @click="saveDrag" v-if="drag_open2" type="text" size="small" class="operation-success">保存</el-button>
				<el-button @click="cancleDrag" v-if="drag_open2" type="text" size="small" class="operation-error">取消</el-button>
			</div>
			<div class="list-wrap" style="width:100%;margin-bottom: 40px;" v-show="drag_open2">
				<draggable 
					style='width:100%;' 
					v-model="imageList2" 
					:options="{
						animation: 150,
						ghostClass: 'sortable-ghost',
						chosenClass: 'chosenClass',
						scroll: true,
						scrollSensitivity: 200
					}">
					<div style="margin-bottom: 50px;display:inline-block;width:25%;" v-for="(item, index) in imageList2" :key="index">
						<img :src="imgUrl+item.PicUrl" alt="" style="width: 188px;height: 266px;">
					</div>
				</draggable>
			</div>
			<div style="display: flex;flex-wrap: wrap;margin-bottom: 40px;"  v-show="!drag_open2">
				<div style="width: 25%;margin-bottom: 50px;" v-for="(item,index) in imageList2" :key="index">
					<div style="display: flex;flex-direction: column;align-items: center;width: 188px;height: 300px;">
						<img :src="imgUrl+item.PicUrl" alt="" style="width: 188px;height: 266px;">
						<div style="text-align: center;font-size: 14px;color: #F56C6C;padding: 10px 30px;cursor:pointer;" @click="deleteImg2(index)">删除</div>
					</div>
				</div>
				<el-upload style="width: 25%;" :action="imgApi" list-type="picture-card" :on-success="handleAvatarSuccessfu2" :file-list="fileList2"
				  accept="image/png,image/jpeg,image/jpg,image/gif" :on-remove="handleRemovefu2" :show-file-list="false">
					<div >
						<i></i>
						<div style="color: #409EFF;font-size: 12px;width:188px;margin-top: 70px;">+添加图片</div>
					</div>
				</el-upload>
			</div>
		</div>

		<el-dialog :visible.sync="deleteDialog" width="500px" center :show-close="false" title="提示">
			<div style="text-align: center;">
				是否确认删除这张海报？
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 120px;" @click="deleteDialog = false">取消</el-button>
				<el-button style="width: 120px;margin-left: 30px;" type="primary" @click="deleteSure">确认删除</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import config from '@/config/index';
	import draggable from "vuedraggable";
	import {
		infoposterlist,
		infoPosterSortEdit,
		infoposterdelete,
		infoposteradd
	} from "@/api/wyUsedInterface.js"
	export default {
		components: {
			draggable
		},
		data(){
			return{
				drag_open: false,
				drag_open2: false,
				tabLoading: false,
				file_list: [],
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				imageList1:[],
				fileList1: [],
				imageList2:[],
				fileList2: [],
				listStateList: [{
						id: 0,
						value: '推手邀请海报'
					},
					{
						id: 1,
						value: '商城推广海报'
					}
				],
				listState: '0',
				currentTab: 0, //当前选择的tab
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				currentPage1: 1,
				pageSize1: 20,
				Total1: 0,
				deleteDialog:false,
				currentDeleteIndex:0
			};
		},
		beforeMount() {
			this.getsterPic()
		},
		methods:{
			// 开启拖拽
			openDrag() {
				if(!this.currentTab){
					this.file_list = JSON.parse(JSON.stringify(this.imageList1)); //数组深拷贝
					this.drag_open = true;
				}else{
					this.file_list = JSON.parse(JSON.stringify(this.imageList2)); //数组深拷贝
					this.drag_open2 = true;
				}
			},
			// 取消拖拽
			cancleDrag() {
				if(!this.currentTab){
					this.imageList1 = this.file_list;
					this.drag_open = false;
				}else{
					this.imageList2 = this.file_list;
					this.drag_open2 = false;
				}
			},
			// 拖拽保存
			async saveDrag(index) {
				let posterList = []
				let imageList = []
				if(!this.currentTab){
					this.drag_open = false;
					imageList = this.imageList1 
				}else{
					this.drag_open2 = false;
					imageList = this.imageList2
				}
				if(!imageList.length){
					return false
				}
				imageList.forEach((t,i) => {
					let item = {
						Id: t.Id,
						Sort: i
					}
					posterList.push(item)
				})
				let result = await infoPosterSortEdit({posterList})
				if(result.IsSuccess&&index){
					this.$message({
						showClose: true,
						type: 'success',
						message: '排序成功'
					})
				}
			},
			async getsterPic(){
				try{
					this.tabLoading = true
					let data = {
						IntoPosterType:this.currentTab,
						Skip : 0,
						Take : 1000000,
					}
					let res = await infoposterlist(data)
					if(res.IsSuccess){
						if(this.currentTab==0){
							this.imageList1 = res.Result.Results
							this.Total = res.Result.Total
						}else{
							this.imageList2 = res.Result.Results
							this.Total1 = res.Result.Total1
						}
					}
					
				}catch(e){
					this.tabLoading = false
				}finally{
					this.tabLoading = false
				}
			},
			async deletePosterPic(item){
				try{
					let data = {
						Id :item.Id
					}
					let res = await infoposterdelete(data)
					if(res.IsSuccess){
						this.$message({showClose: true,
							type: 'success',
							message: res.Message ? res.Message : '删除成功'
						});
						this.getsterPic()
					}
				}catch(e){
				}
			},
			deleteSure(){
				if(this.currentTab==0){
					this.deletePosterPic(this.imageList1[this.currentDeleteIndex])
				}else{
					this.deletePosterPic(this.imageList2[this.currentDeleteIndex])
				}
				this.deleteDialog = false
			},
			deleteImg1(index){
				this.deleteDialog = true
				this.currentDeleteIndex = index
			},
			deleteImg2(index){
				this.deleteDialog = true
				this.currentDeleteIndex = index
			},
			//上传图片
			handleAvatarSuccessfu(res, file) {
				if (['image/png', 'image/jpeg', 'image/jpg', 'image/jpeg', 'image/gif', 'image/PNG', 'image/JPG', 'image/JPEG',
						'image/GIF'
					].indexOf(file.raw.type) == -1) {
					this.$message.error('请上传正确的图片格式');
					return false;
				}
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过2M!');
					return false;
				}
				this.addPosterPic(file.response[0])
			},
			
			async addPosterPic(url){
				try{
					let data = {
						IntoPosterType: this.currentTab,
						PicUrl: url,
						Sort: 0
					}
					let res = await infoposteradd(data)
					if(res.IsSuccess){
						this.getsterPic().then(res => {
							this.saveDrag(0)
						})
						this.$message({showClose: true,
							type: 'success',
							message: res.Message ? res.Message : '添加成功'
						});
					}
					
				}catch(e){
				}
			},
			
			handleRemovefu(file, fileList) {
			},
			
			//顶部tab切换事件
			filterFun(tab, event) {
				if (tab.index == '0') {
					this.currentTab = 0
				} else if (tab.index == '1') {
					this.currentTab = 1
				}
				this.getsterPic()
			},
			
			//上传图片
			handleAvatarSuccessfu2(res, file) {
			
				if (['image/png', 'image/jpeg', 'image/jpg', 'image/jpeg', 'image/gif', 'image/PNG', 'image/JPG', 'image/JPEG',
						'image/GIF'
					].indexOf(file.raw.type) == -1) {
					this.$message.error('请上传正确的图片格式');
					return false;
				}
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过2M!');
					return false;
				}
				
				this.addPosterPic(file.response[0])
			},
			handleRemovefu2(file, fileList) {
			},
		}
	}
</script>

<style lang="less" scoped>
::v-deep .el-upload--picture-card{
	width: 188px !important;
	height: 266px !important;
}
</style>
<style lang="sass" scoped>
.com-image-drag
  &:after
      display: block
      clear: both
      content: ""
  .image-list
    float: left
    &:after
      display: block
      clear: both
      content: ""
    .list-wrap
     float: left
    .image-item
      width: 148px
      height: 148px
      position: relative
      margin-right: 10px
      margin-bottom: 10px
      border: 1px solid #c0ccda
      background-size: 100% 100%
      border-radius: 6px
      float: left
      overflow: hidden
      cursor: pointer
      .label
        width: 46px
        height: 26px
        background-color: #13ce66
        color: #FFFFFF
        transform: rotate(45deg)
        text-align: center
        position: absolute
        right: -17px
        top: -7px
        .icon-success
          transform: rotate(-45deg)
      .mask
        width: 100%
        height: 100%
        border-radius: 6px
        background-color: rgba(0, 0, 0, 0.5)
        position: relative
        .bin
          color: #FFFFFF
          font-size: 20px
          position: absolute
          left: 45%
          top: 43%
  .upload-machine
    float: left
</style>
